globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"D7HIypMJ8JdOPnoVynEJS"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from '@sentry/nextjs'

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  sampleRate: 1, // send 100% of error events
  tracesSampleRate: 0.1, // send 10% of performance transactions
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
  initialScope: {
    tags: { app: process.env.NEXT_PUBLIC_SENTRY_APP },
  },
  release: process.env.VERCEL_URL,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
})
